import { newRequest } from 'std/api/newRequest';
import type { ApiBaseParams } from 'std/api/types';

export type ApiFormaPagamentoConsultarParams = ApiBaseParams &
    Partial<{
        classificacao: ApiFormaPagamentoClassificacao | ApiFormaPagamentoClassificacao[];
        descricao: string;
    }>;

export enum ApiFormaPagamentoClassificacao {
    Dinheiro = 1,
    Cheque = 2,
    CartaoCredito = 3,
    CartaoDebito = 4,
    ContaReceber = 5,
    ContaPagar = 6,
    ValeAlimentacao = 10,
    ValeRefeicao = 11,
    ValePresente = 12,
    ValeCombustivel = 13,
    Boleto = 15,
    Deposito = 16,
    Pix = 17,
    Transferencia = 18,
    SemPagamento = 90,
    Outros = 99,
}

export type ApiFormaPagamento = {
    fop_idpk: number;
    fop_descricao: string;
    fop_empresa_idpk: number;
    fop_classificacao: ApiFormaPagamentoClassificacao | number;
    fop_dias_intervalo: number;
    fop_ultima_alteracao: string;
    fop_financeiro_conta_idpk?: number;
    fop_numero_maximo_parcelas: number;
};

export function apiFormaPagamentoConsultar(
    params?: ApiFormaPagamentoConsultarParams,
): Promise<ApiFormaPagamento[]> {
    return newRequest({
        url: 'FormaPagamento/Consultar',
        type: 'get',
        params,
    });
}
